/* eslint-disable import/no-named-as-default */
import { ReactNode } from 'react';
import Popup from 'reactjs-popup';

import Button from 'components/Button';

import { CloseMiddleIcon } from 'components/Icons';

import s from './Modal.module.scss';

interface Props {
  children: ReactNode;
  trigger?: JSX.Element;
  lockScroll?: boolean;
  open?: boolean;
  onClose?: () => void;
}

const Modal = ({ children, lockScroll = true, open = false, ...props }: Props) => (
  <Popup open={open} modal lockScroll={lockScroll} {...props}>
    <>
      {(close: () => void) => (
        <Button theme="ghost" onClick={close} className={s.closeButton}>
          <CloseMiddleIcon />
        </Button>
      )}
      {children}
    </>
  </Popup>
);

export default Modal;
