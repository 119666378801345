/* eslint-disable @typescript-eslint/no-unsafe-argument */
import { createContext, useCallback, useMemo, useState, ReactNode } from 'react';

import { Auth } from 'config/api';

import { UserType } from 'types/user';
import useLocalStorage from 'hooks/useLocalStorage';

interface Props {
  children: JSX.Element | ReactNode;
}

interface AuthContextType {
  user: UserType | null;
  loading: boolean;
  error: boolean;
  onLogin: (email: string) => void;
}

export const AuthContext = createContext<AuthContextType>({} as AuthContextType);

export default function AuthProvider({ children }: Props): JSX.Element {
  const [, setIdAuth] = useLocalStorage('idAuth', '');

  const [user, setUser] = useState<UserType | null>(null);

  const [error, setError] = useState<boolean>(false);

  const [loading, setLoading] = useState<boolean>(false);

  const onLogin = useCallback(
    async (email: string) => {
      setLoading(true);
      await Auth.authorization(email)
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .then((data: any) => {
          // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
          setUser(data);
          // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
          setIdAuth(data?.id);
          setLoading(false);
        })
        // eslint-disable-next-line @typescript-eslint/no-shadow
        .catch((error) => {
          // eslint-disable-next-line no-console
          console.log('error', error);
          setError(true);
        });
    },
    [setUser, setLoading, setIdAuth]
  );

  const memoedValue = useMemo(
    () => ({
      user,
      loading,
      error,
      onLogin,
    }),
    [user, loading, error]
  );

  return <AuthContext.Provider value={memoedValue}>{children}</AuthContext.Provider>;
}
