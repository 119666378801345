import { useState } from 'react';

import { useDebouncedCallback } from 'use-debounce';

import { Works } from 'config/api';
import { WorksType } from 'types/works';

const useWorks = () => {
  const [works, setWorks] = useState<WorksType[]>([]);

  const [error, setError] = useState<boolean>();

  const [loading, setLoading] = useState<boolean>(false);

  const onFindWorks = useDebouncedCallback(async (value: string): Promise<void> => {
    setLoading(true);
    await Works.getSearchWorks(value)
      .then((data: WorksType[]) => {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        setWorks(data);
        setLoading(false);
      })
      // eslint-disable-next-line @typescript-eslint/no-shadow
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.log('error', error);
        setError(true);
      });
  }, 300);

  return { onFindWorks, works, error, loading };
};

export default useWorks;
