/* eslint-disable @typescript-eslint/no-floating-promises */
/* eslint-disable @typescript-eslint/no-misused-promises */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { createContext, ReactNode, useContext, useEffect, useState } from 'react';

import useLocalStorage from 'hooks/useLocalStorage';
import useWritingProcess from 'hooks/useWritingProcess';

import { WritingProcessType } from 'types/writingProcess';
import useSendWritingProcess from 'hooks/useSendWritingProcess';
import { QUESTION_OBJ } from 'constants/index';
import { AuthorizationContext } from './Authorization';

const textAreaArr = [
  {
    id: '1',
    value: '',
    placeholder: 'Make your first point',
  },
];

export const DataContext = createContext<{
  writingProcess: WritingProcessType[];
  getWritingProcessById: any;
  loadingWritingProcess: boolean;
  onSaveWritingProcess: (body) => void;
  onUpdateWritingProcess: (isDone?) => void;
  onDoneWritingProcess: () => Promise<void>;
  onGetWritingProcess: () => void;
  isSaveData: boolean;
}>(null);

const DataProvider = ({ children }: { children: ReactNode }) => {
  const { isLogin } = useContext(AuthorizationContext);

  const [isSaveData, setIsSaveData] = useState(false);
  const [writingId] = useLocalStorage('writingId', '');

  const [idAuth] = useLocalStorage('idAuth', '');

  const [arr] = useLocalStorage<{ id: string; value: string; placeholder?: string }[]>('quoteArr', textAreaArr);

  const [quoteArrDevelop] = useLocalStorage<{ id: string; value: string; placeholder?: string }[]>('quoteArrDevelop', textAreaArr);

  const [planValue] = useLocalStorage('planValue', '');

  const [questionObj] = useLocalStorage<{ name?: string; id?: string }>(QUESTION_OBJ, {});

  const [introduction] = useLocalStorage('introduction', '');
  const [conclusion] = useLocalStorage('conclusion', '');

  const { onGetWritingProcess, writingProcess, getWritingProcessById, loadingwritingProcess } = useWritingProcess();

  const { onSaveWritingProcess, onUpdateWritingProcess, onDoneWritingProcess } = useSendWritingProcess();

  const handleIsSaveData = () => {
    setIsSaveData(true);
    setTimeout(() => {
      setIsSaveData(false);
    }, 1000);
  };

  const handleSaveWritingProcess = (body) => {
    onSaveWritingProcess(idAuth, body);
    handleIsSaveData();
  };

  const handleUpdateWritingProcess = (isDone?: boolean) => {
    onUpdateWritingProcess(idAuth, writingId, {
      question: questionObj,
      plan: arr,
      quoteArrDevelop,
      develop: { planValue, introduction, conclusion },
      isDone,
    });
    handleIsSaveData();
  };

  const handleDoneWritingProcess = () => {
    const resultDone = onDoneWritingProcess(writingId, '', {
      question: questionObj,
      plan: arr,
      quoteArrDevelop,
      develop: { planValue, introduction, conclusion },
      isDone: true,
    });
    handleIsSaveData();

    return resultDone;
  };

  useEffect(() => {
    if (isLogin) {
      void onGetWritingProcess();
    }
  }, [onGetWritingProcess]);

  return (
    <DataContext.Provider
      value={{
        onGetWritingProcess: () => (isLogin ? onGetWritingProcess() : () => null),
        writingProcess: writingProcess,
        loadingWritingProcess: loadingwritingProcess,
        getWritingProcessById: getWritingProcessById,
        onSaveWritingProcess: (body) => handleSaveWritingProcess(body),
        onUpdateWritingProcess: (isDone?: boolean) => handleUpdateWritingProcess(isDone),
        onDoneWritingProcess: () => handleDoneWritingProcess(),
        isSaveData,
      }}
    >
      {children}
    </DataContext.Provider>
  );
};

export default DataProvider;
