import cn from 'classnames';

import s from './Button.module.scss';

interface TOwnProps {
  size?: 'small' | 'medium' | 'large' | 'extra-large';
  theme?: 'primary' | 'secondary' | 'gray' | 'gray-blue' | 'black' | 'blue' | 'ghost' | 'white';
  className?: string;
  type?: 'button' | 'submit' | 'reset';
  onClick?: (param: React.MouseEvent<HTMLElement>) => void;
  fullWidth?: boolean;
  rounded?: boolean;
  uppercase?: boolean;
}

const Button: React.FC<TOwnProps & JSX.IntrinsicElements['button']> = ({
  size = 'medium',
  className,
  theme = 'primary',
  children,
  disabled = false,
  onClick,
  type = 'button',
  fullWidth = false,
  rounded = false,
  uppercase = false,
  ...props
}) => {
  const classes = cn(
    theme === 'ghost' ? s.ghost : s.root,
    s[`size-${size}`],
    s[`theme-${theme}`],
    {
      [s.fullWidth]: fullWidth,
      [s.rounded]: rounded,
      [s.uppercase]: uppercase,
    },
    className
  );

  return (
    <button type={type} className={classes} onClick={onClick} disabled={disabled} {...props}>
      {children}
    </button>
  );
};

export default Button;
