import { Popup } from 'reactjs-popup';
import { CloseSmallIcon } from 'components/Icons';
import s from './Buy.module.scss';
import { useContext } from 'react';
import { BuyContext } from '../../context/Buy';
import BuyViewModel from 'components/Buy/BuyViewModel';

const BuyView = () => {
  const { show } = useContext(BuyContext);
  const { onClose, onChange, onBuy, creditPrice, totalPrice, count, error, promoCode, setPromoCode, onPromoCode } = BuyViewModel();

  const errorView = () => {
    if (error?.status) {
      return <div className={s.error}>{error.message}</div>;
    }
  };

  return (
    <Popup onClose={() => onClose()} open={show} position="right center">
      <div className={s.modalCredit}>
        <p className={s.buyTitle}>
          <span>Buy more credits</span>
          <span className={s.buyClose} onClick={() => onClose()}>
            <CloseSmallIcon />
          </span>
        </p>
        <p className={s.buyDescription}>Please choose the number of credits</p>
        <div className={s.buyInputContainer}>
          <input value={count} onChange={(event) => void onChange(parseInt(event.target.value))} />
          <span>£{creditPrice}</span>
        </div>
        <button onClick={() => void onBuy()}>Pay £{totalPrice}</button>
        <div className={s.orLineCol}>
          <p className={s.orLine}></p>
          <span className={s.orLineTx}>Or use promo code</span>
          <p className={s.orLine}></p>
        </div>
        <div>
          <div className={s.buyInputContainer}>
            <input value={promoCode} onChange={(event) => void setPromoCode(event.target.value)} />
            <button onClick={() => void onPromoCode()}>Submit</button>
          </div>
        </div>
        {errorView()}
      </div>
    </Popup>
  );
};

export default BuyView;
