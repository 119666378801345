import { useState, useMemo, createContext, useEffect } from 'react';

interface Props {
  children: JSX.Element;
}

interface RouterContext {
  page?: string;
  value?: string;
  setPage?: (param: string) => void;
}

export const pagesMapping = {
  home: 'home',
  work: 'work',
  main: 'main',
  compilation: 'compilation',
  review: 'review',
  submitted: 'submitted',
};

export const RoutingContext = createContext<RouterContext>({ page: pagesMapping.home });

export default function Router({ children }: Props) {
  const [page, setPage] = useState<string>(pagesMapping.home);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [page]);

  const value = useMemo<RouterContext>(() => ({ page, setPage }), [page, setPage]);

  return <RoutingContext.Provider value={value}>{children}</RoutingContext.Provider>;
}
