/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-call */
import { useState, useContext } from 'react';

import ReactMarkdown from 'react-markdown';

import cn from 'classnames';

import Title from 'components/Title';
import Text from 'components/Text';
import Modal from 'components/Modal';
import Button from 'components/Button';

import useLocalStorage from 'hooks/useLocalStorage';
// import useSendWritingProcess from 'hooks/useSendWritingProcess';
import useClearLocalStorage from 'hooks/useClearLocalStorage';

import { RoutingContext, pagesMapping } from 'context/Routing';

import { StepsContext, stepsMapping } from 'context/Steps';

import { QUESTION_OBJ } from 'constants/index';

import s from './ReviewScreen.module.scss';
import { DataContext } from 'context/Data';

const ReviewScreen = () => {
  const [isModal, setIsModal] = useState(false);

  const [questionObj, setQuestionObj] = useLocalStorage<{ name?: string; id?: string }>(QUESTION_OBJ, {});

  // const [idAuth] = useLocalStorage('idAuth', '');

  const [, setIsModalSuccsess] = useLocalStorage('isModalSuccsess', false);

  const { onClearWritingProcess } = useClearLocalStorage();

  const [planValue] = useLocalStorage('planValue', '');
  const [introduction] = useLocalStorage('introduction', '');
  const [conclusion] = useLocalStorage('conclusion', '');

  // const { onSendWritingProcess } = useSendWritingProcess();
  const { onUpdateWritingProcess, onDoneWritingProcess } = useContext(DataContext);

  const { setPage } = useContext(RoutingContext);

  const { setStep } = useContext(StepsContext);

  const handleGotoHome = () => {
    if (setPage) {
      setPage(pagesMapping.home);
    }
    if (setStep) {
      setStep(stepsMapping.first);
    }
  };

  const handleOpenModal = () => {
    setIsModal(true);
  };

  const handleCloseModal = () => {
    setIsModal(false);
  };

  const handleSendResult = async () => {
    // @typescript-eslint/no-misused-promises
    await onDoneWritingProcess();
    // onUpdateWritingProcess(true);

    setIsModalSuccsess(true);
    onClearWritingProcess();
    setQuestionObj({});
    handleGotoHome();
  };
  const handleBack = () => {
    if (setPage) {
      setPage(pagesMapping.compilation);
    }
  };

  return (
    <>
      <Modal open={isModal} onClose={handleCloseModal}>
        <div className={s.modal}>
          <Title level={3} className={s.modalTitle}>
            Comfirm sending to review
          </Title>

          <div className={s.modalFooter}>
            <Button size="large" theme="gray-blue" fullWidth onClick={handleCloseModal}>
              Go back
            </Button>

            <Button size="large" fullWidth onClick={() => void handleSendResult()}>
              Next
            </Button>
          </div>
        </div>
      </Modal>

      <div className={cn(s.root, 'container')}>
        <Title level={2} className={s.title}>
          {questionObj.name}
        </Title>

        <div className={s.content}>
          <Title level={5} className={s.subTitle}>
            Introduction
          </Title>

          <Text size="small-medium">{introduction}</Text>
        </div>

        <div className={s.content}>
          <Title level={5} className={s.subTitle}>
            Body
          </Title>

          <Text size="small-medium" inline>
            <ReactMarkdown>{planValue}</ReactMarkdown>
          </Text>
        </div>

        <div className={s.content}>
          <Title level={5} className={s.subTitle}>
            Conclusion
          </Title>

          <Text size="small-medium">{conclusion}</Text>
        </div>

        <div className={s.footer}>
          <Button onClick={handleBack} theme="gray-blue" size="large">
            Exit
          </Button>

          <Button onClick={handleOpenModal} size="large">
            Next
          </Button>
        </div>
      </div>
    </>
  );
};

export default ReviewScreen;
