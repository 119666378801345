import { useContext, useState } from 'react';
import { BuyContext } from '../../context/Buy';

export default function HeaderViewModel() {
  const { onSetShow } = useContext(BuyContext);

  function onBuyCredit() {
    onSetShow(true);
  }

  return {
    onBuyCredit,
  };
}
