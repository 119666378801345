import { useContext, useEffect } from 'react';

import { stepsMapping, StepsContext } from 'context/Steps';

import cn from 'classnames';

import Button from 'components/Button';
import BigTitle from 'components/BigTitle';

import Start from 'modules/steps/components/Start';
import Plan from 'modules/steps/components/Plan';
import Develop from 'modules/steps/components/Develop';
import EditSubmit from 'modules/steps/components/EditSubmit';

import useLocalStorage from 'hooks/useLocalStorage';

import { QUESTION_OBJ } from 'constants/index';

import useQuestion from 'modules/screens/hooks/useQestion';

import s from './CompilationScreen.module.scss';

const CompilationScreen = () => {
  const [questionObj] = useLocalStorage<{ name?: string; id?: string }>(QUESTION_OBJ, {});
  const { step, setStep = () => null } = useContext(StepsContext);

  const { question, onGetQuestionById } = useQuestion();

  useEffect(() => {
    if (questionObj?.id) {
      void onGetQuestionById(questionObj.id);
    }
  }, [questionObj]);

  return (
    <div className={cn(s.root, 'container')}>
      <BigTitle>
        {stepsMapping.start === step ? 'Getting started' : null}
        {stepsMapping.first === step ? 'Planning' : null}
        {stepsMapping.second === step ? 'Writing' : null}
        {stepsMapping.third === step ? 'Editing' : null}
      </BigTitle>

      <div className={s.buttons}>
        <Button
          theme={stepsMapping.start === step ? 'blue' : 'gray'}
          rounded
          size="small"
          uppercase
          onClick={() => setStep(stepsMapping.start)}
        >
          Getting started
        </Button>
        <Button
          theme={stepsMapping.first === step ? 'blue' : 'gray'}
          rounded
          size="small"
          uppercase
          onClick={() => setStep(stepsMapping.first)}
        >
          {'Step 1 point & example (P.E.)'}
        </Button>

        <Button
          theme={stepsMapping.second === step ? 'blue' : 'gray'}
          rounded
          size="small"
          uppercase
          onClick={() => setStep(stepsMapping.second)}
        >
          {'Step 2 explain & Develop (E.D.)'}
        </Button>

        <Button
          theme={stepsMapping.third === step ? 'blue' : 'gray'}
          rounded
          size="small"
          uppercase
          onClick={() => setStep(stepsMapping.third)}
        >
          {'Step 3 Edit & submit'}
        </Button>
      </div>

      {stepsMapping.start === step ? <Start /> : null}
      {stepsMapping.first === step ? <Plan question={question} title={questionObj?.name} /> : null}
      {stepsMapping.second === step ? <Develop title={questionObj?.name} /> : null}
      {stepsMapping.third === step ? <EditSubmit title={questionObj?.name} /> : null}
    </div>
  );
};
export default CompilationScreen;
