import Header from '../../components/Header';

import s from './CommonLayout.module.scss';

interface Props {
  children: JSX.Element;
}

export default function CommonLayout({ children }: Props) {
  return (
    <>
      <Header />

      <main className={s.root}>{children}</main>
    </>
  );
}
