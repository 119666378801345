import { useCallback } from 'react';

import useLocalStorage from 'hooks/useLocalStorage';

const textAreaArr = [
  {
    id: '1',
    value: '',
    placeholder: 'Make your first point',
  },
];

const useClearLocalStorage = () => {
  const [, setIntroduction] = useLocalStorage('introduction', '');
  const [, setConclusion] = useLocalStorage('conclusion', '');
  const [, setArr] = useLocalStorage<{ id?: string; value: string; placeholder?: string }[]>('quoteArr', textAreaArr);

  const [, setQuoteArrDevelop] = useLocalStorage<{ id: string; value: string; placeholder?: string }[]>('quoteArrDevelop', textAreaArr);
  const [, setPlanValue] = useLocalStorage('planValue', '');

  const onClearWritingProcess = useCallback(() => {
    setIntroduction('');
    setConclusion('');
    setArr(textAreaArr);
    setQuoteArrDevelop(textAreaArr);
    setPlanValue('');
  }, []);

  return { onClearWritingProcess };
};

export default useClearLocalStorage;
