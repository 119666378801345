import { useState, useContext, useMemo } from 'react';

import Title from 'components/Title';
import Button from 'components/Button';
import SelectWork from 'components/SelectWork';

import { RoutingContext, pagesMapping } from 'context/Routing';

import useLocalStorage from 'hooks/useLocalStorage';

import { WORK_OBJ } from 'constants/index';

import s from './WorkScreen.module.scss';

const WorkScreen = () => {
  const [workIdObj, setWorkObj] = useLocalStorage<{ name?: string; id?: string }>(WORK_OBJ, {});

  const [valueInput, setValueInput] = useState('');

  const isCanNextStep = useMemo(() => valueInput === workIdObj?.name, [valueInput, workIdObj]);

  const { setPage } = useContext(RoutingContext);

  const handleNextStep = () => {
    if (setPage) {
      setPage(pagesMapping?.main);
    }
  };

  return (
    <div className="container-small">
      <div className={s.wrapper}>
        <Title level={1} weight="extra-bold" className={s.title}>
          What is the title of your text?
        </Title>
      </div>

      <div className={s.select}>
        <SelectWork
          value={valueInput}
          onChange={(elem = '') => setValueInput(elem)}
          className={s.selectItem}
          setIsCanNextStep={() => null}
          // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
          setValueObj={(obj = {}) => setWorkObj(obj)}
        />
        <Button size="extra-large" className={s.button} onClick={handleNextStep} disabled={!isCanNextStep}>
          Next
        </Button>
      </div>
    </div>
  );
};
export default WorkScreen;
