/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-shadow */
import { useState, useCallback } from 'react';

import { WritingProcess } from 'config/api';

import useLocalStorage from './useLocalStorage';

import { SaveDataType } from 'types/writingProcess';

const useSendWritingProcess = () => {
  const [error, setError] = useState<boolean>();

  const [, setWritingId] = useLocalStorage('writingId', '');

  const [loading, setLoading] = useState<boolean>(false);

  const onSaveWritingProcess = useCallback(
    async (id: string, data: SaveDataType | unknown) => {
      setLoading(true);
      await WritingProcess.saveWritingProcess(id, data)
        .then((resData) => {
          // eslint-disable-next-line no-console
          if (resData) {
            setWritingId(resData.id);
          }
          setLoading(false);
        })
        // eslint-disable-next-line @typescript-eslint/no-shadow
        .catch((error) => {
          // eslint-disable-next-line no-console
          console.log('error', error);
          setError(true);
        });
    },
    [setLoading, setError]
  );

  const onUpdateWritingProcess = useCallback(
    async (id: string, writingId: string, data: SaveDataType) => {
      setLoading(true);
      await WritingProcess.updateWritingProcess(id, writingId, data)
        .then(() => {
          setLoading(false);
        })
        // eslint-disable-next-line @typescript-eslint/no-shadow
        .catch((error) => {
          // eslint-disable-next-line no-console
          console.log('error', error);
          setError(true);
        });
    },
    [setLoading, setError]
  );

  const onSendWritingProcess = useCallback(
    async (id: string, data: { title: string }) => {
      setLoading(true);
      await WritingProcess.sendWritingProcess(id, data)
        .then(() => {
          setLoading(false);
        })
        // eslint-disable-next-line @typescript-eslint/no-shadow
        .catch((error) => {
          // eslint-disable-next-line no-console
          console.log('error', error);
          setError(true);
        });
    },
    [setLoading, setError]
  );

  const onDoneWritingProcess = useCallback(
    async (writingId: string, result: string, data: SaveDataType) => {
      setLoading(true);
      await WritingProcess.doneWritingProcess(writingId, result, data)
        .then(() => {
          setLoading(false);
        })
        // eslint-disable-next-line @typescript-eslint/no-shadow
        .catch((error) => {
          // eslint-disable-next-line no-console
          console.log('error', error);
          setError(true);
        });
    },
    [setLoading, setError]
  );

  // eslint-disable-next-line no-console
  // console.log('writingId', writingId);

  return { onSendWritingProcess, onSaveWritingProcess, onUpdateWritingProcess, onDoneWritingProcess, error, loading };
};

export default useSendWritingProcess;
