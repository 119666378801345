import { Question } from '../../../config/api';
import { useCallback, useEffect, useState } from 'react';

interface IQuestion {
  id: string;
  name: string;
  shallIntroduction: string;
  howPoints: string;
  whatIsQuestion: string;
  learnMore: string;
}

interface IProcessData {
  question: IQuestion;
}

interface IProcess {
  id: string;
  data: IProcessData;
  userId: string
}

const QuestionViewModel = () => {
  const [currQuestion, setCurrQuestion] = useState<IQuestion>()
  const writeId = JSON.parse(localStorage.getItem('writingId')) || '';
  const process = <IProcess[]>JSON.parse(localStorage.getItem('allProcess')) || [];
  const question = <IQuestion>JSON.parse(localStorage.getItem('questionObj'));

  const currentProcess = process.find((item) => item.id === writeId);


  const cQuestion = useCallback( async () => {
    const result = await Question.getQuestionByID(currentProcess?.data?.question.id || question?.id) as IQuestion;
    setCurrQuestion(result);
  }, [])

  // cQuestion().then(r => {});
  const currentQuestion = (question || currentProcess?.data?.question) as IQuestion;

  return { cQuestion, currentProcess, currentQuestion, currQuestion }
}

export default QuestionViewModel;
