import { createContext, useMemo, useState, ReactNode } from 'react';

interface Props {
  children: JSX.Element | ReactNode;
}

interface IBuyContext {
  show: boolean;
  onSetShow: (status: boolean) => void;
}

export const BuyContext = createContext<IBuyContext>({} as IBuyContext);

export default function BuyProvider({ children }: Props): JSX.Element {
  const [show, setShow] = useState<boolean>(false);
  const onSetShow = (status: boolean) => setShow(status);

  const contextValue = useMemo(
    () => ({
      show,
      onSetShow,
    }),
    [show]
  );

  return <BuyContext.Provider value={contextValue}>{children}</BuyContext.Provider>;
}
