import { useContext, useState } from 'react';
import { AuthorizationContext } from '../../../../context/Authorization';
import { pagesMapping, RoutingContext } from '../../../../context/Routing';
import { BuyContext } from '../../../../context/Buy';
import useClearLocalStorage from '../../../../hooks/useClearLocalStorage';
import useLocalStorage from '../../../../hooks/useLocalStorage';
import { QUESTION_OBJ } from '../../../../constants';
import { WritingProcess } from '../../../../config/api';

export default function AccountScreenViewModel() {
  const { onSetShow } = useContext(BuyContext);
  const { user } = useContext(AuthorizationContext);
  const { setPage } = useContext(RoutingContext);
  const { onClearWritingProcess } = useClearLocalStorage();
  const [, setQuestionObj] = useLocalStorage<{ name?: string; id?: string }>(QUESTION_OBJ, {});
  const [removeWritingProcessIds, setRemoveWritingProcessIds] = useState<string[]>([]);

  function onStart() {
    if (user.balance && user.balance > 0) {
      setPage(pagesMapping.work);
      onClearWritingProcess();
      setQuestionObj({});
    } else {
      onSetShow(true);
    }
  }

  function onCheckBalance(): boolean {
    if (!(user.balance && user.balance > 0)) {
      onSetShow(true);
      return false;
    }

    return true;
  }

  async function onRemove(id: string): Promise<void> {
    const deleteData = await WritingProcess.deleteWritingProcess(id);
    if (parseInt(String(deleteData), 10) > 0) {
      setRemoveWritingProcessIds([...removeWritingProcessIds, id]);
    }
  }

  return {
    onStart,
    onCheckBalance,
    onRemove,
    removeWritingProcessIds,
  };
}
