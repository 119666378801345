import { useContext, useState } from 'react';
import { auth, ITokens, refreshToken, verification } from 'modules/screens/components/AuthScreen/AuthScreenModel';
import { AuthorizationContext } from '../../../../context/Authorization';

enum EError {
  EMAIL,
  PIN,
}

interface IError {
  type: EError;
  message: string;
  status: boolean;
}

export default function AuthScreenViewModel() {
  const [isPinView, setPinView] = useState<boolean>(false);
  const [error, setError] = useState<IError>();
  const [email, setEmail] = useState<string>('');
  const [pin, setPin] = useState<string>('');

  const { onSetUser, onSetToken, onLogout, tokens, isRefreshRequest, setRefreshRequest } = useContext(AuthorizationContext);

  async function onAuth() {
    if (validateEmail()) {
      const result = await auth(email);

      onSetUser(result);
      setPinView(true);
    } else {
      setError({ type: EError.EMAIL, message: 'Email is wrong', status: true });
    }
  }

  async function onVerification() {
    if (validatePin()) {
      try {
        const result = await verification(parseInt(pin, 10), email);
        onSetToken(<ITokens>result);
      } catch (err) {
        setError({ type: EError.PIN, message: 'Pin is wrong', status: true });
      }
    } else {
      setError({ type: EError.PIN, message: 'Pin must be number', status: true });
    }
  }

  async function onRefreshToken() {
    try {
      if (isRefreshRequest) {
        console.log('onRefreshToken');
        setRefreshRequest(false);
        const result = await refreshToken(tokens);
        onSetToken(<ITokens>result);
      }
    } catch (err) {
      void onLogout();
    }
  }

  const onChangeEmail = (value: string) => {
    setError({ type: EError.EMAIL, message: '', status: false });
    setEmail(value);
  };

  const onChangePin = (value: string) => {
    setError({ type: EError.PIN, message: '', status: false });
    setPin(value);
  };

  const validateEmail = () =>
    String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );

  const validatePin = () => /^\d+$/.test(pin);

  return {
    isPinView,
    error,
    email,
    pin,
    onChangePin,
    onAuth,
    onChangeEmail,
    onVerification,
    onRefreshToken,
  };
}
