/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-explicit-any */
import cn from 'classnames';

import { useCombobox } from 'downshift';

import useWorks from 'hooks/useWorks';

import { QuestionType } from 'types/question';

import useClearLocalStorage from 'hooks/useClearLocalStorage';

import useLocalStorage from 'hooks/useLocalStorage';
import { QUESTION_OBJ } from 'constants/index';

import s from './Select.module.scss';

interface SelectProps {
  value: string;
  label?: string;
  isError?: boolean;
  isDisabled?: boolean;
  onChange: (value?: string) => void;
  setIsCanNextStep: (value?: boolean) => void;
  setValueObj: (obj: any) => void;
  className?: string;
}

const itemToString = (item: QuestionType | null) => item?.name ?? '';

const SelectWorks = ({
  isError,
  isDisabled,
  value,
  className,
  onChange,
  setValueObj,
  setIsCanNextStep,
}: SelectProps) => {
  const { works = [], onFindWorks } = useWorks();

  const { onClearWritingProcess } = useClearLocalStorage();

  const [, setQuestionObj] = useLocalStorage<{ name?: string; id?: string }>(QUESTION_OBJ, {});

  const { isOpen, getMenuProps, highlightedIndex, getComboboxProps, getInputProps, getItemProps } = useCombobox({
    items: works,
    itemToString: itemToString,
    onSelectedItemChange: (changes) => {
      if (changes?.selectedItem) {
        onChange(changes?.selectedItem?.name);
        setValueObj(changes?.selectedItem);
        setIsCanNextStep(true);
        onClearWritingProcess();
        setQuestionObj(null);
      }
    },

    onInputValueChange: ({ inputValue }) => {
      onChange(inputValue);
      void onFindWorks(inputValue || '');
    },
  });

  const valueClasses = cn(s.value, { [s.active]: isOpen, [s.error]: isError, [s.disabled]: isDisabled });

  const listClasses = cn(s.list, { [s.active]: isOpen });

  return (
    <div className={cn(s.root, className)} {...getComboboxProps()}>
      <input className={valueClasses} {...getInputProps({ value: value ?? '' })} />

      <ul className={listClasses} {...getMenuProps()}>
        {isOpen
          ? works.map((item, index) => (
              <li
                key={item.name || String(item)}
                className={cn(s.option, highlightedIndex === index && s.active)}
                {...getItemProps({ item, index })}
              >
                {item.name}
              </li>
            ))
          : null}
      </ul>
    </div>
  );
};

export default SelectWorks;
