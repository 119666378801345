import { ReactNode } from 'react';
import cn from 'classnames';
import s from './Prompt.module.scss';

interface TOwnProps {
  className?: string;
  children: ReactNode | string;
}

const Prompt = ({ children, className }: TOwnProps) => <div className={cn(s.root, className)}>{children}</div>;

export default Prompt;
