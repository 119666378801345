import { useContext, useEffect } from 'react';

import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

import { StepsContext, stepsMapping } from 'context/Steps';

import Button from 'components/Button';
import Title from 'components/Title';
import Text from 'components/Text';
import Accordion from 'components/Accordion';
import Prompt from 'components/Prompt';
import QuotesArray from 'components/QuotesArray';

import DustBin from '../DustBin';

// import Modal from 'components/Modal';

import useLocalStorage from 'hooks/useLocalStorage';

// import useClearLocalStorage from 'hooks/useClearLocalStorage';

import smileImg from 'assets/smiles/smile.png';

import s from './Plan.module.scss';
import { DataContext } from 'context/Data';

interface Props {
  question: {
    id: string;
    name: string;
    categorie: Array<{
      id: string;
      name: string;
      definition: string;
      quote: Array<{
        id: string;
        name: string;
        quote?: string;
        commentaries?: {
          id: string;
          name: string;
          commentary: string;
        };
      }>;
    }>;
  };
  title?: string;
}

const textAreaArr = [
  {
    id: '1',
    value: '',
    placeholder: 'Make your first point',
  },
];

const Plan = ({ question, title }: Props) => {
  const [arr, setArr] = useLocalStorage<{ id: string; value: string; placeholder?: string }[]>('quoteArr', textAreaArr);

  const { onUpdateWritingProcess } = useContext(DataContext);

  const onAddTextArea = () => {
    setArr((prevState) => [
      ...prevState,
      {
        id: `${new Date().getTime()}`,
        value: '',
      },
    ]);
  };

  const handleChange = (value: string, index: number) => {
    setArr((prevState) => {
      const newArr = prevState.slice();
      newArr[index].value = value;

      const lastColumn = newArr[newArr.length - 1];
      if (lastColumn.value === '' || lastColumn.value.length === 0) {
        return newArr;
      } else {
        return [
          ...newArr,
          {
            id: `${new Date().getTime()}`,
            value: '',
          },
        ];
      }
    });
  };

  const handleDropChange = (value: string, index: number) => {
    // const trimmedValue = value.replace(/^\s+|\s+$/g, '');
    //
    // setArr((prevState) => {
    //   const newArr = prevState.slice();
    //
    //   newArr[index].value = newArr[index].value ? newArr[index].value + trimmedValue : trimmedValue;
    //
    //   return newArr;
    // });
    setArr((prevState) => {
      const newArr = prevState.slice();
      newArr[index].value += "\n" + value;

      const lastColumn = newArr[newArr.length - 1];
      if (lastColumn.value === '' || lastColumn.value.length === 0) {
        return newArr;
      } else {
        return [
          ...newArr,
          {
            id: `${new Date().getTime()}`,
            value: '',
          },
        ];
      }
    });
  };

  // const [openModal, setOpenModal] = useState<boolean>(false);

  const { setStep } = useContext(StepsContext);

  const handleNextStep = () => {
    if (setStep) {
      setStep(stepsMapping.second);
      onUpdateWritingProcess();
    }
  };

  // const handleOpenModal = () => {
  //   setOpenModal(true);
  // };

  // const handleCloseModal = () => {
  //   setOpenModal(false);
  // };

  useEffect(() => {
    onUpdateWritingProcess();
  }, [arr.length]);

  return (
    <>
      <DndProvider backend={HTML5Backend}>
        {/* <Modal open={openModal} onClose={handleCloseModal}>
          <div className={s.modal}>
            <Title level={3}>Characterisation</Title>

            <Text muted>Hint</Text>
            {queteArray.map((quete) => (
              <div className={s.modalQuote}>
                <QuoteItem {...quete} key={quete.id} isCommentVisible={false} />

                <Button theme="gray-blue" size="small" uppercase fullWidth>
                  add to stack
                </Button>
              </div>
            ))}
          </div>
        </Modal> */}

        <div className={s.row}>
          <aside className={s.aside}>
            <Prompt className={s.prompt}>
              <p style={{margin: '0xp'}}><b>Welcome to your POINT+EXAMPLE activity!</b></p>
              The themes below house a treasure trove of carefully selected quotations that can spark ideas for your compelling argument. Take your time to read each quotation attentively so you could formulate your response to it.  Your response will then become your original point.
              {/*<p style={{margin: '0xp'}}><b>Think of the ORDER in which you wish to arrange your points.</b></p>*/}
            </Prompt>
            <div className={s.accordion}>
              {question?.categorie.map((item) => (
                <Accordion title={item.name} definition={item.definition} key={item.id || item.name}>
                  <QuotesArray quotes={item.quote || []} />
                </Accordion>
              ))}
            </div>
          </aside>

          <div className={s.cell}>
            <Title level={4} className={s.subtitle}>
              {title}
            </Title>

            <Prompt className={s.description}>
              Each box below represents one of your points. Once you have formulated your point, drag and drop the quotations by which you wish to support your point into the corresponding box. Remember, effective quoting doesn't mean extensive quoting. Instead, feel free to paraphrase the quotation or blend it with your own words, ensuring it flows smoothly within your sentence.
            </Prompt>

            {arr.map((item, index) => (
              <DustBin
                onChange={(value: string) => handleChange(value, index)}
                onDropChange={(value: string) => handleDropChange(value, index)}
                // onDropChange={(value: string) => handleChange(value, index)}
                value={item.value}
                key={item.id || index}
                placeholder={item.placeholder || ''}
                onAddComponentTextArea={onAddTextArea}
              />
            ))}

            {/* <Button
              theme="gray-blue"
              fullWidth
              size="small"
              onClick={onAddTextArea}
              className={s.addButton}
              disabled={isPureLastItem}
            >
              Add another point
            </Button>
            */}

            {/* <Button theme="gray-blue" fullWidth size="small" onClick={handleClearArr} className={s.pointButton}>
              Clear Points
            </Button> */}

            <div className={s.footer}>
              <Text weight="medium" className={s.footerText}>
                <img src={smileImg} width="40" height="40" className={s.img} />
                Are you happy with your plan? If yes, press continue!
              </Text>
              <Button onClick={handleNextStep} size="large">
                Continue
              </Button>
            </div>
          </div>
        </div>
      </DndProvider>
    </>
  );
};

export default Plan;
