import { useState, useContext, useMemo } from 'react';

import Title from 'components/Title';
import Text from 'components/Text';
import Button from 'components/Button';
import Select from 'components/Select';

import { RoutingContext, pagesMapping } from 'context/Routing';

import useLocalStorage from 'hooks/useLocalStorage';

import { QUESTION_OBJ } from 'constants/index';

import couplesImg from 'assets/smiles/couples.png';
import { DataContext } from 'context/Data';

import s from './MainScreen.module.scss';
import { StepsContext, stepsMapping } from '../../../../context/Steps';

const MainScreen = () => {
  const [questionObj, setQuestionObj] = useLocalStorage<{ name?: string; id?: string }>(QUESTION_OBJ, {});

  const [valueInput, setValueInput] = useState('');

  const isCanNextStep = useMemo(() => valueInput === questionObj?.name, [valueInput, questionObj]);

  const { setPage } = useContext(RoutingContext);

  const { onSaveWritingProcess } = useContext(DataContext);

  const { setStep } = useContext(StepsContext);

  const handleNextStep = () => {
    if (setStep) {
      setStep(stepsMapping.start);
    }
    if (setPage) {
      setPage(pagesMapping?.compilation);
      onSaveWritingProcess({ question: questionObj });
    }
  };

  return (
    <div className="container-small">
      <div className={s.wrapper}>
        <Title level={1} weight="extra-bold" className={s.title}>
          Enter the keyword of your essay question
        </Title>

        <Text size="extra-large" className={s.description}>
          Select the topic closest to yours
          <img src={couplesImg} width="40" height="40" className={s.img} />
        </Text>
      </div>

      <div className={s.select}>
        <Select
          value={valueInput}
          onChange={(elem = '') => setValueInput(elem)}
          className={s.selectItem}
          setIsCanNextStep={() => null}
          // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
          setValueObj={(obj = {}) => setQuestionObj(obj)}
        />
        <Button size="extra-large" className={s.button} onClick={handleNextStep} disabled={!isCanNextStep}>
          Next
        </Button>
      </div>
    </div>
  );
};
export default MainScreen;
