import { useState, useMemo, createContext, useEffect } from 'react';

interface Props {
  children: JSX.Element;
}

interface RouterContext {
  step?: string;
  value?: string;
  setStep?: (param: string) => void;
}

export const stepsMapping = {
  start: 'start',
  first: 'first',
  second: 'second',
  third: 'third',
};

export const StepsContext = createContext<RouterContext>({ step: stepsMapping.start });

export default function Router({ children }: Props) {
  const [step, setStep] = useState<string>(stepsMapping.start);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [step]);

  const value = useMemo<RouterContext>(() => ({ step, setStep }), [step, setStep]);

  return <StepsContext.Provider value={value}>{children}</StepsContext.Provider>;
}
