import { useContext, useEffect, useState } from 'react';

import Button from 'components/Button';
import Title from 'components/Title';
import Text from 'components/Text';
import TextArea from 'components/TextArea';
import Prompt from 'components/Prompt';
import Modal from 'components/Modal';

import useLocalStorage from 'hooks/useLocalStorage';

import { RoutingContext, pagesMapping } from 'context/Routing';

import { DataContext } from 'context/Data';

import s from './EditSubmit.module.scss';

const EditSubmit = ({ title }: { title: string }) => {
  const [planValue, setPlanValue] = useLocalStorage('planValue', '');
  const [introduction, setIntroduction] = useLocalStorage('introduction', '');
  const [conclusion, setConclusion] = useLocalStorage('conclusion', '');

  const [openModal, setOpenModal] = useState<boolean>(false);
  const [isFirstOpenModal, setIsFirstOpenModal] = useState<boolean>(true);

  const { onUpdateWritingProcess } = useContext(DataContext);

  const handleCloseModal = () => {
    setOpenModal(false);
    setIsFirstOpenModal(false);
  };

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const { setPage } = useContext(RoutingContext);

  const handleGoToReview = () => {
    if (setPage) {
      setPage(pagesMapping.review);
      onUpdateWritingProcess();
    }
  };

  const isOpenModal = openModal && isFirstOpenModal;

  useEffect(() => {
    onUpdateWritingProcess();
  }, [planValue, introduction, conclusion]);

  return (
    <>
      <Modal open={isOpenModal} onClose={handleCloseModal}>
        <div className={s.modal}>
          <Title level={3} className={s.modalTitle}>
            Introduction guides
          </Title>

          <Text className={s.modalText} size="small-medium">
            Othello begins to suspect that Desdemona is not virtuous in spite of her fair looks. According to one
            popular Renaissance belief, the outward appearance should normally reflect the inward quality of things.
            Iago picks up on this thought to suggest that Desdemona behaved unnaturally when she fell in love with a
            black man. Ironically, Othello readily accepts this thought – after this conversation, he calls Iago
            ‘honest’. Ironically, he himself abuses his race by saying that his blackness gives him the right to be
            savage and cruel. His pronouncement ‘Haply, for I am black’ suggests that Othello feels an outsider in
            Venice because he cannot be as broad-minded and liberal as the native Venetians are.
            <br />
            <br />
            The epithet ‘my fair warrior’ that Othello bestows on Desdemona is hardly anything more than a husband’s
            affectionate nickname for his wife. But in the context of the play, this epithet is not just arbitrary.
            Aware of his wife’s military ambition, he wants to please her – this is why ‘my fair warrior’ sounds
            slightly patronizing. The audience might observe that in reality, Desdemona’s military achievements come
            down to her arriving in Cypress ahead of her husband. Desdemona’s failure to help Cassio will result in her
            self-reproach as an ‘unhandsome warrior’. Othello’s sarcasm is addressed to Desdemona and Venetian court
            ladies in general. He praises them for being men’s seducers – such as Cleopatra, for example, who ‘might lie
            by an emperor’s side and command him tasks’.
            <br />
            <br />
            Emilia plays on words. While Desdemona believes that adultery is an unforgivable crime (impossible to commit
            ‘for all the world’), Emilia reminds Desdemona that ‘the world is a huge thing’ – meaning one’s desires. For
            the sake of possessing fame or power, adultery is a small vice.
          </Text>
          <Button onClick={handleCloseModal} size="large">
            Continue
          </Button>
        </div>
      </Modal>

      <div className={s.root}>
        <div className={s.wrapper}>
          <Title level={4} className={s.title}>
            {title}
          </Title>

          <Prompt className={s.description}>
            Try to leave a bit of time between finishing your essay and starting your editing process. Re-read your
            essay, taking a fresh look at it. Start editing; look at its overarching structure. Does one point follow
            logically on from the other? Would you like to add some contextual materials or some critical commentaries?
            Make sure that the points you’ve made actually respond to your assignment question. Finally, add an
            introduction and a conclusion.
          </Prompt>
        </div>

        <div>
          <TextArea
            value={introduction}
            onChange={(event) => setIntroduction(event.target.value)}
            onFocus={handleOpenModal}
            placeholder="Add an introduction"
            className={s.textArea}
          />
          <TextArea value={planValue} className={s.textArea} onChange={(event) => setPlanValue(event.target.value)} />
          <TextArea
            value={conclusion}
            placeholder="Add a conclusion"
            className={s.textArea}
            onChange={(event) => setConclusion(event.target.value)}
            onFocus={handleOpenModal}
          />
        </div>

        <Button onClick={handleGoToReview} size="large">
          Review your essay
        </Button>
      </div>
    </>
  );
};

export default EditSubmit;
