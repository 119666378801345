/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable no-nested-ternary */
import { useState } from 'react';

import cn from 'classnames';

import { useDrag } from 'react-dnd';

import Button from 'components/Button';

import { useSwiper } from 'swiper/react';

import QuoteItem from './QuoteItem';

import s from './Quote.module.scss';

export const ItemTypes = {
  BOX: 'box',
};

interface TOwnProps {
  name: string;
  quote: string;
  onClick?: () => void;
  isVisibleButtons: boolean;

  commentaries?: {
    id: string;
    name?: string;
    commentary?: string;
  };
}

const Quote = ({ name, quote, commentaries = null, isVisibleButtons }: TOwnProps) => {
  const [{ isDragging }, drag] = useDrag(() => ({
    type: ItemTypes.BOX,
    item: { quote },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
      handlerId: monitor.getHandlerId(),
    }),
  }));

  const opacity = isDragging ? 0.4 : 1;

  const [isCommentVisible, setIsCommentVisible] = useState<boolean>(false);

  const handleShowComments = () => {
    if (commentaries) {
      setIsCommentVisible(true);
    }
  };

  const handleHideComments = () => {
    if (commentaries) {
      setIsCommentVisible(false);
    }
  };

  const swiper = useSwiper();

  return (
    <div className={cn(s.root, isCommentVisible && s.active, isDragging && s.drag)} style={{ opacity }} ref={drag}>
      <div className={s.wrapper}>
        <QuoteItem
          name={isCommentVisible ? 'Commentary' : ''}
          subTitlte={!isCommentVisible ? name : ''}
          quote={isCommentVisible ? commentaries?.commentary : quote}
          onShowComments={handleShowComments}
          onHideComments={handleHideComments}
          isCommentVisible={isCommentVisible}
        />

        {!isCommentVisible && isVisibleButtons ? (
          <div className={s.buttons}>
            <Button theme="gray-blue" size="small" uppercase fullWidth onClick={() => swiper.slidePrev()}>
              previous
            </Button>
            <Button theme="gray-blue" size="small" uppercase fullWidth onClick={() => swiper.slideNext()}>
              next
            </Button>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default Quote;
