import { useContext, useEffect } from 'react';
import cn from 'classnames';

import Button from 'components/Button';
import ShowMoreTextBlock from 'components/ShowMoreTextBlock';

import { StepsContext, stepsMapping } from 'context/Steps';

import useLocalStorage from 'hooks/useLocalStorage';

import { QUESTION_OBJ } from 'constants/index';

import s from './Start.module.scss';
import QuestionViewModel from 'modules/steps/components/QuestionViewModel';

const INTRODUCTION_TEXT =
  'Your essay introduction is important because it presents the main subject of discussion - it tells your reader what to expect in the essay. However, at the stage of drafting your essay, it’s often best to get the structure of the whole essay done, first, and leave writing the introduction until last. So, instead of immersing yourself into devising a full-fledged intro, focus on your points and make sure that your argument is well-structured and coherent.<br><br> If you prefer to begin with an introduction, jump to step 3. Please don’t stay there too long!';
  // 'Not necessarily, no. Your essay introduction is important because it presents the main subject of discussion - it tells your reader what to expect in the essay. However, at the stage of drafting your essay, it’s often best to get the structure of the whole essay done, first, and leave writing the introduction until last. So, instead of immersing yourself into devising a full-fledged intro, just make a note of the key-words in your question at the top of the page to keep yourself focused and start drafting your points.<br /><br />If you prefer to begin with an introduction, jump to step 3. Please don’t stay there too long!';

const HOW_TO_POINT_TEXT =
  'We believe that the best way to craft an original point is to respond to the studied text itself. To assist you in this process, we have curated a collection of carefully selected quotes from the texts you are studying. Welcome to our "Quotation Banks"—your gateway to finding textual evidence that will reinforce and support your unique arguments.';
  // 'The best way to make a good point is to start from the text itself. When you get to your <b>Planning page</b> you will find on the right-hand part of the screen several sets of quotations, categorised as literary elements and devices. Read each quote carefully and decide whether you’d like to respond to it 👍 or not 👎. Your response will then become your point - your “informed personal response” <span>(AO1)</span> - that will be easy to support! All you need to do is just to prune your quote in the way you like or embed it seamlessly into your sentence.<br /><br />Each quotation from the bank is supplied with the commentary, which explains how it works as a literary device <span>(AO2)</span> and suggests relevant contextual materials <span>(AO3)</span> and critical opinions <span>(AO5)</span>. Please note that every commentary you read is designed to provoke your own response – so don’t take it for granted!';

const HOW_DOES_IT_WORK = 'On the right-hand side of your screen, you will find a set of thought-provoking quotations categorized according to the literary elements present in your texts. These categories include characters, themes, and even abstract notions conveyed within the texts. Each quotation is accompanied by a commentary that highlights its significance as a literary structure within the context of the whole text.<br><br>It\'s important to note that the commentaries provided by EssayEasy are interpretations, intended to inspire your own thinking. You have the freedom to either consider them or challenge them, as you embark on the exciting journey of crafting your own original points.'

const Start = () => {
  const [questionObj] = useLocalStorage<{
    whatIsQuestion?: string;
    shallIntroduction?: string;
    howPoints?: string;
    learnMore?: string | null;
  }>(QUESTION_OBJ, {});
  const {currentQuestion, currQuestion, cQuestion} = QuestionViewModel();
  const { setStep } = useContext(StepsContext);

  useEffect(() => {
    cQuestion().then(r => {});
  }, [])

  const handleNextStep = () => {
    if (setStep) {
      setStep(stepsMapping?.first);
    }
  };

  return (
    <div className={cn(s.root, 'container')}>
      <ShowMoreTextBlock
        data={{
          title: 'Question',
          description: currQuestion?.name || '',
        }}
      />

      <ShowMoreTextBlock
        data={{
          title: 'What is my question about?',
          smallDesc: 'Before diving into our Quotation Banks, take a moment to explore the "What is my question about" section. It will provide you with valuable insights and suggestions that can help you stay on track and tackle your essay question effectively. Consider it as a compass to orient your thoughts and exploration of the text.',
          description: currQuestion?.whatIsQuestion || currentQuestion?.whatIsQuestion,
          descriptionShowMore: currQuestion?.learnMore,
        }}
      />

      <ShowMoreTextBlock data={{ title: 'Shall I begin with an introduction?', description: INTRODUCTION_TEXT }} />

      <ShowMoreTextBlock data={{ title: 'How do I make my points?', description: HOW_TO_POINT_TEXT }} />

      <ShowMoreTextBlock data={{ title: 'HOW DOES IT WORK?', description: HOW_DOES_IT_WORK }} />

      <Button onClick={handleNextStep} size="large">
        Continue
      </Button>
    </div>
  );
};
export default Start;
