import { ReactNode } from 'react';
import cn from 'classnames';

import s from './Text.module.scss';

type HTMLTag = 'p' | 'span';

interface TOwnProps {
  size?: 'extra-small' | 'small' | 'small-medium' | 'medium' | 'large' | 'extra-large';
  weight?: 'regular' | 'bold' | 'medium';
  children: ReactNode | string;
  className?: string;
  muted?: boolean;
  inline?: boolean;
}

const Text: React.FC<TOwnProps> = ({
  size = 'small',
  className,

  weight = 'regular',
  muted = false,
  inline = false,
  children,
}) => {
  const classes = cn(s.root, s[`size-${size}`], s[`weight-${weight}`], className, {
    [s.muted]: muted,
  });

  const Tag: HTMLTag = inline ? 'span' : 'p';

  return <Tag className={classes}>{children}</Tag>;
};

export default Text;
