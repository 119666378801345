/* eslint-disable import/no-named-as-default */
import React from 'react';
import ReactDOM from 'react-dom/client';
import Router from './context/Routing';
import Steps from './context/Steps';
// import AuthContext from './context/Auth';
import AuthorizationContext from './context/Authorization';
import BuyContext from './context/Buy';
import DataContext from './context/Data';

import './styles/globals.scss';

import App from './App';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <>
    {/*<AuthContext>*/}
    <AuthorizationContext>
      <BuyContext>
        <DataContext>
          <Router>
            <Steps>
              <App />
            </Steps>
          </Router>
        </DataContext>
      </BuyContext>
    </AuthorizationContext>
    {/*</AuthContext>*/}
  </>
);
