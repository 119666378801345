import { useState, useCallback } from 'react';

import { WritingProcess } from 'config/api';
import { WritingProcessType } from 'types/writingProcess';

const useWritingProcess = () => {
  const [writingProcess, setWritingProcess] = useState<WritingProcessType[]>();

  const [error, setError] = useState<boolean>();

  const [loadingwritingProcess, setLoadingwritingProcess] = useState<boolean>(false);

  const onGetWritingProcess = useCallback(async () => {
    setLoadingwritingProcess(true);
    await WritingProcess.getWritingProcess()
      .then((resData: WritingProcessType[]) => {
        localStorage.setItem('allProcess', JSON.stringify(resData))
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        setWritingProcess(resData);
        setLoadingwritingProcess(false);
      })
      // eslint-disable-next-line @typescript-eslint/no-shadow
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.log('error', error);
        setError(true);
      });
  }, [setWritingProcess, setLoadingwritingProcess]);

  const getWritingProcessById = useCallback((idParam: string) => {
    if (writingProcess?.length > 0) {
      const findedIndex = writingProcess.findIndex(({ id }) => id === idParam);
      return findedIndex > -1 ? writingProcess[findedIndex] : null;
    }
    return null;
  }, []);

  return { onGetWritingProcess, getWritingProcessById, writingProcess, error, loadingwritingProcess };
};

export default useWritingProcess;
