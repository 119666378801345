import axios, { AxiosError } from 'axios';

export interface IUser {
  id: string;
  pin: string;
  balance: number;
  email: string;
  f_name: string;
  l_name: string;
}

export interface ITokens {
  accessToken: string;
  refreshToken: string;
}

export async function auth(email: string): Promise<IUser> {
  const result = await axios.post(`${process.env.REACT_APP_API_ROOT}/auth/log-in`, {
    email,
  });

  return <IUser>result.data;
}

export async function verification(pin: number, email: string): Promise<ITokens | AxiosError> {
  try {
    const result = await axios.post(`${process.env.REACT_APP_API_ROOT}/auth/verification`, {
      email,
      pin,
    });

    return <ITokens>result.data;
  } catch (err) {
    return Promise.reject(<AxiosError>err);
  }
}

export async function refreshToken(token: ITokens): Promise<ITokens | AxiosError> {
  try {
    const result = await axios.post(`${process.env.REACT_APP_API_ROOT}/auth/refresh-token`, {
      ...token,
    });

    return <ITokens>result.data;
  } catch (err) {
    return Promise.reject(<AxiosError>err);
  }
}
