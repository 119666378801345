import { useContext, useEffect } from 'react';
import cn from 'classnames';

import Title from 'components/Title';
import Text from 'components/Text';
import Button from 'components/Button';
import Input from 'components/Input';
import AccountScreen from '../AccountScreen';

import laptopImg from 'assets/smiles/laptop.png';

import s from './AuthScreen.module.scss';
import useAuthScreenViewModel from './AuthScreenViewModel';
import { AuthorizationContext } from '../../../../context/Authorization';

const AuthScreen = () => {
  const { isLogin } = useContext(AuthorizationContext);
  const { pin, isPinView, email, error, onAuth, onChangeEmail, onChangePin, onVerification, onRefreshToken } =
    useAuthScreenViewModel();

  useEffect(() => {
    const refreshToken = async () => {
      await onRefreshToken();
    };

    refreshToken().catch(console.error);
  }, []);

  const errorView = () => {
    if (error?.status) {
      return <div className={s.error}>{error.message}</div>;
    }
  };

  const formButton = () => {
    if (!isPinView) {
      return (
        <Button size="extra-large" className={s.button} onClick={() => void onAuth()}>
          Next
        </Button>
      );
    } else {
      return (
        <Button size="extra-large" className={s.button_hidden} onClick={() => void onAuth()}>
          Next
        </Button>
      );
    }
  };

  return (
    <div className={cn(s.root, 'container-small')}>
      {isLogin ? (
        <AccountScreen />
      ) : (
        <>
          <div className={s.wrapper}>
            <Title level={1} weight="extra-bold" className={s.title}>
              Welcome to Essay Easy
            </Title>

            <Text size="extra-large" className={s.description}>
              Please create an account or login
              <img src={laptopImg} width="40" height="40" className={s.img} />
            </Text>
          </div>
          <div className={s.select}>
            <Input
              placeholder="Enter your email"
              value={email}
              onChange={(event) => void onChangeEmail(event.target.value)}
              type="email"
              required
            />
            {formButton()}
          </div>
          {isPinView ? (
            <div className={`${s.select} ${s.pincode}`}>
              <Input
                placeholder="Please enter the pin code sent to your email"
                value={pin}
                onChange={(event) => void onChangePin(event.target.value)}
                type="text"
              />
              <Button size="extra-large" className={s.button} onClick={() => void onVerification()}>
                Next
              </Button>
            </div>
          ) : (
            <></>
          )}
          {errorView()}
        </>
      )}
    </div>
  );
};
export default AuthScreen;
