import { useState, useCallback } from 'react';

import { Question } from 'config/api';
import useLocalStorage from '../../../hooks/useLocalStorage';
import { QUESTION_OBJ } from '../../../constants';

const useQuestion = () => {
  const [question, setQuestion] = useState<{
    id: string;
    name: string;
    categorie: Array<{
      id: string;
      name: string;
      definition: string;
      quote: Array<{
        id: string;
        name: string;
        quote?: string;
        commentaries?: {
          id: string;
          name: string;
          commentary: string;
        };
      }>;
    }>;
  }>();
  const [, setQuestionObj] = useLocalStorage<{ name?: string; id?: string }>(QUESTION_OBJ, {});
  const [error, setError] = useState<boolean>();

  const [loading, setLoading] = useState<boolean>(false);

  const onGetQuestionById = useCallback(async (id: string): Promise<void> => {
    setLoading(true);
    await Question.getQuestionByID(id)
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      .then((data: any) => {
        // comment: question is not updated
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        const questionData = Object.assign({}, data);
        delete questionData.categorie;
        delete questionData.categoryQuote;
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        // Save to local storage
        // console.log('questionData', questionData.whatIsQuestion);
        window.localStorage.setItem(QUESTION_OBJ, JSON.stringify(questionData));

        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        setQuestion(data);
        setLoading(false);
      })
      // eslint-disable-next-line @typescript-eslint/no-shadow
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.log('error', error);
        setError(true);
      });
  }, []);

  return { onGetQuestionById, question, error, loading };
};

export default useQuestion;
