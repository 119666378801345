import React, { InputHTMLAttributes, useState } from 'react';

import cn from 'classnames';

import s from './Input.module.scss';

export interface InputPropsTypes extends InputHTMLAttributes<HTMLInputElement> {
  className?: string;
  isError?: boolean;
}

const Input = React.forwardRef<HTMLInputElement, InputPropsTypes>(
  ({ disabled = false, isError = false, name, type = 'text', onChange, placeholder, className, ...props }, ref) => {
    const [hasFocus, setFocus] = useState(false);

    const handleFocus = (event: React.FocusEvent<HTMLInputElement>) => {
      const { onFocus } = props;

      setFocus(true);
      onFocus?.(event);
    };

    const handleBlur = (event: React.FocusEvent<HTMLInputElement>) => {
      const { onBlur } = props;

      setFocus(false);
      onBlur?.(event);
    };

    const classNames = cn(s.root, className, hasFocus && s.focus, disabled && s.disabled, isError && s.error);

    return (
      <input
        {...props}
        className={classNames}
        id={name}
        type={type}
        name={name}
        onFocus={handleFocus}
        onBlur={handleBlur}
        onChange={onChange}
        disabled={disabled}
        placeholder={placeholder}
        ref={ref}
      />
    );
  }
);

export default Input;
