import { useContext } from 'react';

import CommonLayout from './layouts/CommonLayout';

import { pagesMapping, RoutingContext } from 'context/Routing';

import AuthScreen from 'modules/screens/components/AuthScreen';
import MainScreen from 'modules/screens/components/MainScreen';
import CompilationScreen from 'modules/screens/components/CompilationScreen';
import ReviewScreen from 'modules/screens/components/ReviewScreen';
import WorkScreen from 'modules/screens/components/WorkScreen';
import ReviewScreenSubmitted from 'modules/screens/components/ReviewScreenSubmitted';

const App = () => {
  const { page } = useContext(RoutingContext);

  return (
    <CommonLayout>
      <div className="main-wrapper">
        {pagesMapping.home === page && <AuthScreen />}
        {pagesMapping.work === page && <WorkScreen />}
        {pagesMapping.main === page && <MainScreen />}
        {pagesMapping.compilation === page && <CompilationScreen />}
        {pagesMapping.review === page && <ReviewScreen />}
        {pagesMapping.submitted === page && <ReviewScreenSubmitted />}
      </div>
    </CommonLayout>
  );
};
export default App;
