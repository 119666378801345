import './DefinitionView.module.s.scss';

const DefinitionView = ({ title }: { title: string }) => {
  const tooltip = title && title.length > 0 ? 'tooltip' : '';

  return (
    <div className={`${tooltip} tooltip_def`}>
      View definition
      <span className="tooltiptext">{title}</span>
    </div>
  );
};

export default DefinitionView;
