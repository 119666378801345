import { FC, useState } from 'react';
import { useDrop } from 'react-dnd';
import cn from 'classnames';

import Text from 'components/Text';
import TextArea from 'components/TextArea';

import s from './DustBin.module.scss';

const ItemTypes = {
  BOX: 'box',
};

export interface DustbinProps {
  onAddComponentTextArea?: () => void;
  onChange: (value: string) => void;
  onDropChange: (value: string) => void;
  value: string;
  placeholder?: string;
}

export interface DustbinState {
  hasDropped: boolean;
  hasDroppedOnChild: boolean;
}

export const Dustbin: FC<DustbinProps> = ({
  onDropChange = () => null,
  onAddComponentTextArea = () => null,
  onChange,
  value = '',
  placeholder,
}) => {
  const [hasDropped, setHasDropped] = useState<boolean>(false);

  const [{ canDrop, isOver }, drop] = useDrop(
    () => ({
      accept: ItemTypes.BOX,
      drop(item: { quote: string }, monitor) {
        const didDrop = monitor.didDrop();
        if (didDrop) {
          return;
        }
        onDropChange(item?.quote);
        setHasDropped(true);
        if (!hasDropped) {
          onAddComponentTextArea();
        }
      },
      collect: (monitor) => ({
        isOver: monitor.isOver(),
        canDrop: monitor.canDrop(),
      }),
    }),
    [setHasDropped, hasDropped]
  );

  const isActive = canDrop && isOver;

  const isActiveTextarea = !!value;

  return (
    <div ref={drop} className={cn(s.root, isActive && s.active)}>
      <TextArea
        value={value}
        onChange={(event) => onChange(event.target.value)}
        className={cn(s.textarea, isActiveTextarea && s.active, canDrop && isOver && s.border)}
      />

      {!isActiveTextarea ? (
        <div className={cn(s.elem, isActive && s.active)}>
          <Text weight="bold" className={s.title}>
            {placeholder || 'Make your next point'}
          </Text>
          <Text muted>Drag the quote from the list in the left column</Text>
        </div>
      ) : null}
    </div>
  );
};

export default Dustbin;
