/* eslint-disable @typescript-eslint/no-explicit-any */
import cn from 'classnames';

import { useCombobox } from 'downshift';

import useSearch from 'hooks/useSearch';

import { QuestionType } from 'types/question';

import useClearLocalStorage from 'hooks/useClearLocalStorage';

import s from './Select.module.scss';
import { WORK_OBJ } from 'constants/index';
import useLocalStorage from 'hooks/useLocalStorage';

interface SelectProps {
  value: string;
  label?: string;
  isError?: boolean;
  isDisabled?: boolean;
  onChange: (value?: string) => void;
  setIsCanNextStep: (value?: boolean) => void;
  setValueObj: (obj: any) => void;
  className?: string;
}

const itemToString = (item: QuestionType | null) => item?.name ?? '';

const Select = ({ isError, isDisabled, value, className, onChange, setValueObj, setIsCanNextStep }: SelectProps) => {
  const { questions = [], onFindQuestions } = useSearch();

  const [workIdObj] = useLocalStorage<{ name?: string; id?: string }>(WORK_OBJ, {});

  const { onClearWritingProcess } = useClearLocalStorage();

  const { isOpen, getMenuProps, highlightedIndex, getComboboxProps, getInputProps, getItemProps } = useCombobox({
    items: questions,
    itemToString: itemToString,
    onSelectedItemChange: (changes) => {
      if (changes?.selectedItem) {
        onChange(changes?.selectedItem?.name);
        setValueObj(changes?.selectedItem);
        setIsCanNextStep(true);
        onClearWritingProcess();
      }
    },

    onInputValueChange: ({ inputValue }) => {
      onChange(inputValue);
      if (workIdObj) {
        void onFindQuestions(inputValue || '', workIdObj.id);
      }
    },
  });

  const valueClasses = cn(s.value, { [s.active]: isOpen, [s.error]: isError, [s.disabled]: isDisabled });

  const listClasses = cn(s.list, { [s.active]: isOpen });

  return (
    <div className={cn(s.root, className)} {...getComboboxProps()}>
      <input className={valueClasses} {...getInputProps({ value: value ?? '' })} />

      <ul className={listClasses} {...getMenuProps()}>
        {isOpen
          ? questions.map((item, index) => (
              <li
                key={item.name || String(item)}
                className={cn(s.option, highlightedIndex === index && s.active)}
                {...getItemProps({ item, index })}
              >
                {item.name}
              </li>
            ))
          : null}
      </ul>
    </div>
  );
};

export default Select;
