import { createContext, useMemo, useState, ReactNode } from 'react';
import jwt_decode from 'jwt-decode';
import { ITokens, IUser } from 'modules/screens/components/AuthScreen/AuthScreenModel';

interface Props {
  children: JSX.Element | ReactNode;
}

interface IAuthorizationContext {
  isRefreshRequest: boolean;
  isLogin: boolean;
  user: IUser;
  tokens: ITokens;
  onSetUser: (user: IUser) => void;
  onSetToken: (tokens: ITokens) => void;
  setLogin: (status: boolean) => void;
  onLogout: () => void;
  setRefreshRequest: (status: boolean) => void;
}

export const AuthorizationContext = createContext<IAuthorizationContext>({} as IAuthorizationContext);

export default function AuthorizationProvider({ children }: Props): JSX.Element {
  const [isRefreshRequest, setRefreshRequest] = useState<boolean>(true);
  const [user, setUser] = useState<IUser>();
  const [tokens, setTokens] = useState<ITokens>({
    accessToken: localStorage.getItem('accessToken'),
    refreshToken: localStorage.getItem('refreshToken'),
  });
  const [isLogin, setLogin] = useState<boolean>(false);

  const onSetUser = (value: IUser) => setUser(value);
  const onSetToken = (value: ITokens) => {
    localStorage.setItem('accessToken', value.accessToken);
    localStorage.setItem('refreshToken', value.refreshToken);
    setTokens(value);
    setUser(jwt_decode(value.accessToken));
    setLogin(true);
    // setRefreshRequest(true);
  };
  const onLogout = () => {
    setTokens({ accessToken: null, refreshToken: null });
    setLogin(false);
    localStorage.removeItem('accessToken');
    localStorage.removeItem('refreshToken');
  };

  const contextValue = useMemo(
    () => ({
      isRefreshRequest,
      isLogin,
      user,
      tokens,
      setLogin,
      onSetUser,
      onSetToken,
      onLogout,
      setRefreshRequest,
    }),
    [user, tokens, isLogin, isRefreshRequest]
  );

  return <AuthorizationContext.Provider value={contextValue}>{children}</AuthorizationContext.Provider>;
}
