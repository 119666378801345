/* eslint-disable @typescript-eslint/no-unsafe-return */
import axios, { AxiosResponse } from 'axios';

import { QuestionType } from 'types/question';

import { UserType } from 'types/user';

import { SaveDataType } from 'types/writingProcess';

const instance = axios.create({
  baseURL: process.env.REACT_APP_API_ROOT,
  withCredentials: true,
  headers: {
    'Content-Type': 'application/json',
  },
});

// eslint-disable-next-line @typescript-eslint/no-unsafe-return
const responseBody = (response: AxiosResponse) => response.data;

const requests = {
  get: (url: string) =>
    instance
      .get(url, { headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` } })
      .then(responseBody),
  post: (url: string, body: {}) =>
    instance
      .post(url, body, { headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` } })
      .then(responseBody),
  put: (url: string, body: {}) =>
    instance
      .put(url, body, { headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` } })
      .then(responseBody),
  delete: (url: string) =>
    instance
      .delete(url, { headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` } })
      .then(responseBody),
};

export const Question = {
  getSearchQuestions: (value: string, workId: string): Promise<QuestionType[]> =>
    requests.get(`/question/search?search=${value}&workId=${workId}`),
  getQuestionByID: (id: string): Promise<QuestionType> => requests.get(`/question/${id}/categories-quotes`),
};

export const Works = {
  getSearchWorks: (value: string): Promise<QuestionType[]> => requests.get(`/works/search?search=${value}`),
};

export const Auth = {
  authorization: (email: string): Promise<UserType> => requests.post('/auth/authorization', { email }),
};

export const WritingProcess = {
  getWritingProcess: (): Promise<QuestionType[]> => requests.get(`/user/writing/process`),

  sendWritingProcess: (id: string, data: { title: string }): Promise<QuestionType[]> =>
    requests.post('/user/writing/process', { userId: id, data }),

  sendResultToEmail: (id: string, result: string): Promise<UserType> => requests.post(`/user/done/${id}`, { result }),

  saveWritingProcess: (id: string, data: SaveDataType): Promise<QuestionType> =>
    requests.post('/user/writing/process', { userId: id, data }),

  updateWritingProcess: (id: string, writingId: string, data: SaveDataType): Promise<QuestionType> =>
    requests.put('/user/writing/process', { userId: id, writingId, data }),

  doneWritingProcess: (writingId: string, result: string, data: SaveDataType): Promise<QuestionType> =>
    requests.post('/user/done', { writingId, result, data }),

  deleteWritingProcess: (writingId: string): Promise<number> => requests.delete(`/user/writing/process/${writingId}`),
};

export const PassQuote = {
  onPassQuote: (id: string, categorieId: string, quoteId: string) =>
    instance
      .post(`/question/${id}/pass`, {
        categorieId,
        quoteIds: [quoteId],
      })
      .then(responseBody),
};
