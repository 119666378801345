import { useState } from 'react';

import { useDebouncedCallback } from 'use-debounce';

import { Question } from 'config/api';
import { QuestionType } from 'types/question';

const useSearch = () => {
  const [questions, setQuestions] = useState<QuestionType[]>([]);

  const [error, setError] = useState<boolean>();

  const [loading, setLoading] = useState<boolean>(false);

  const onFindQuestions = useDebouncedCallback(async (value: string, workId: string): Promise<void> => {
    setLoading(true);
    await Question.getSearchQuestions(value, workId)
      .then((data: QuestionType[]) => {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        setQuestions(data);
        setLoading(false);
      })
      // eslint-disable-next-line @typescript-eslint/no-shadow
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.log('error', error);
        setError(true);
      });
  }, 300);

  return { onFindQuestions, questions, error, loading };
};

export default useSearch;
