import axios, { AxiosError } from 'axios';

export interface ICreatePayment {
  url: string;
}

export async function createPayment(count: number, accessToken: string): Promise<ICreatePayment | AxiosError> {
  try {
    const result = await axios.post(
      `${process.env.REACT_APP_API_ROOT}/payment/create`,
      {
        count,
      },
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );

    return <ICreatePayment>result.data;
  } catch (err) {
    return Promise.reject(<AxiosError>err);
  }
}

export async function createPromoCode(promoCode: string, accessToken: string): Promise<ICreatePayment | AxiosError> {
  try {
    const result = await axios.post(
      `${process.env.REACT_APP_API_ROOT}/payment/promo-code`,
      {
        promoCode,
      },
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );

    return <ICreatePayment>result.data;
  } catch (err) {
    return Promise.reject(<AxiosError>err);
  }
}
