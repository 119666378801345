import logo from 'assets/images/loader.svg';

import s from './Spinner.module.scss';

const Spinner = () => (
  <div className={s.root}>
    <img src={logo} alt="loader" width="30" height="30" />
  </div>
);

export default Spinner;
