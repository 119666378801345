/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import { useContext, useEffect } from 'react';
import cn from 'classnames';
import remove from '../../../../assets/icons/remove.svg';

import Title from 'components/Title';
import Text from 'components/Text';
import Button from 'components/Button';
import Spinner from 'components/Spinner';
import Modal from 'components/Modal';

import { RoutingContext, pagesMapping } from 'context/Routing';

import { StepsContext, stepsMapping } from 'context/Steps';

import { AuthContext } from 'context/Auth';

import useLocalStorage from 'hooks/useLocalStorage';

import file from 'assets/smiles/file.png';

import { DataContext } from 'context/Data';
import { QUESTION_OBJ } from 'constants/index';
import useClearLocalStorage from 'hooks/useClearLocalStorage';

import s from './AccountScreen.module.scss';
import AccountScreenViewModel from 'modules/screens/components/AccountScreen/AccountScreenViewModel';
import useAuthScreenViewModel from 'modules/screens/components/AuthScreen/AuthScreenViewModel';

interface Props {
  id: string;
  status: string;
  data?: {
    title?: string;
    question?: {
      name?: string;
    };
    isDone?: boolean;
  };
}

const AccountScreen = () => {
  const { onRefreshToken } = useAuthScreenViewModel();

  useEffect(() => {
    const refreshToken = async () => {
      await onRefreshToken();
    };

    refreshToken().catch(console.error);
  }, []);

  const [, setWritingId] = useLocalStorage('writingId', '');
  const [, setQuestionObj] = useLocalStorage<{ name?: string; id?: string }>(QUESTION_OBJ, {});
  const [, setIdAuth] = useLocalStorage('idAuth', '');

  const [, setArr] = useLocalStorage<{ id: string; value: string; placeholder?: string }[]>('quoteArr', []);

  const [, setPlanValue] = useLocalStorage('planValue', '');

  const [, setIntroduction] = useLocalStorage('introduction', '');
  const [, setConclusion] = useLocalStorage('conclusion', '');
  const [, setV2QuestionObj] = useLocalStorage('questionObj', '');

  const [, setQuoteArrDevelop] = useLocalStorage<{ id: string; value: string; placeholder?: string }[]>(
    'quoteArrDevelop',
    []
  );

  const [isModalSuccsess, setIsModalSuccsess] = useLocalStorage('isModalSuccsess', false);

  // const { onClearWritingProcess } = useClearLocalStorage();

  const { onStart, onCheckBalance, onRemove, removeWritingProcessIds } = AccountScreenViewModel();
  const { setPage } = useContext(RoutingContext);
  const { setStep } = useContext(StepsContext);
  const { user, loading } = useContext(AuthContext);

  useEffect(() => {
    if (user) {
      setIdAuth(user.id);
      onGetWritingProcess();
    }

    localStorage.removeItem('writingId');
    localStorage.removeItem('quoteArr');
    localStorage.removeItem('quoteArrDevelop');
    localStorage.removeItem('planValue');
    localStorage.removeItem('introduction');
    localStorage.removeItem('conclusion');
    localStorage.removeItem('questionObj');
  }, [user]);

  // const handleNextStep = () => {
  //   if (setPage) {
  //     setPage(pagesMapping.work);
  //     onClearWritingProcess();
  //     setQuestionObj({});
  //   }
  // };

  const handleGoToCompilation = (id: string, data: any, status: string) => {

    if(status === 'DONE') {
      window.location.hash = `#${id}`;
      setPage(pagesMapping.submitted);
      return;
    }

    if (onCheckBalance()) {
      if (id) {
        setWritingId(id);
      }

      // comment: question is not updated
      // if (data) {
      //   // setQuestionObj(data.question);
      // }

      if (data?.plan) {
        setArr(data?.plan || []);
      }

      if (data?.quoteArrDevelop) {
        setQuoteArrDevelop(data?.quoteArrDevelop);
      }

      if (data?.develop) {
        setPlanValue(data?.develop?.planValue || '');
        setIntroduction(data?.develop?.introduction || '');
        setConclusion(data?.develop?.conclusion || '');
      }

      if (data?.question) {
        setV2QuestionObj(data?.question || '');
      }

      if (setPage) {
        setPage(pagesMapping.compilation);
      }

      if (setStep) {
        setStep(stepsMapping.start);
      }
    }
  };

  const { loadingWritingProcess, writingProcess, onGetWritingProcess } = useContext(DataContext);

  useEffect(() => {
    onGetWritingProcess();
  }, []);

  const isWritingProcess = writingProcess && writingProcess?.length > 0;

  if (loading || loadingWritingProcess) {
    return <Spinner />;
  }

  return (
    <>
      <Modal
        open={isModalSuccsess}
        onClose={() => {
          setIsModalSuccsess(false);
        }}
      >
        <div className={s.modal}>
          <Title level={3} className={s.modalTitle}>
            Your essay was successfully submitted
          </Title>
          <Text muted className={s.modalText}>
            We will be in touch shortly.
          </Text>
          <Button size="large" fullWidth onClick={() => setIsModalSuccsess(false)}>
            Go to my account
          </Button>
        </div>
      </Modal>

      <div className={cn(s.root, 'container-small')}>
        <div className={s.wrapper}>
          <Title level={2} className={s.title}>
            Your Account
          </Title>
          <div className={s.section}>
            <Text size="medium" inline>
              {user?.email ? user?.email : ''}
            </Text>
            {isWritingProcess ? (
              <Button onClick={() => onStart()} className={s.button} size="large">
                Start your essay
              </Button>
            ) : null}
          </div>
        </div>

        {isWritingProcess ? (
          writingProcess
            .filter((item) => !removeWritingProcessIds.includes(item.id))
            .map(({ id, data, status }: Props) => (
              <div key={id} className={s.listcol}>
                <Button
                  className={s.item}
                  theme="ghost"
                  // disabled={status === 'DONE'}
                  onClick={() => handleGoToCompilation(id, data, status)}
                >
                  <Text size="medium">{data?.question?.name || id}</Text>

                  {status === 'DONE' ? (
                    <Text className={s.status}>Submitted</Text>
                  ) : (
                    <Text className={s.statusProggress}>In proggress</Text>
                  )}
                </Button>

                { status !== 'DONE' &&
                  <button className={s.removeItem} onClick={() => onRemove(id)}>
                  <img src={remove} />
                </button>
                }
              </div>
            ))
        ) : (
          <div className={s.pureItem}>
            <div className={s.pureWrap}>
              <img src={file} alt="pic" width="40" height="40" />
              <Text size="medium">You don't have essays yet</Text>
            </div>
            <Button onClick={() => onStart()} className={s.button} size="large">
              Start your essay
            </Button>
          </div>
        )}
      </div>
    </>
  );
};
export default AccountScreen;
