import { useContext, useState } from 'react';
import { BuyContext } from '../../context/Buy';
import { createPayment, createPromoCode, ICreatePayment } from 'components/Buy/BuyModel';
import { AuthorizationContext } from '../../context/Authorization';

enum EError {
  COUNT,
  PAYMENT,
}

interface IError {
  type: EError;
  message: string;
  status: boolean;
}

export default function BuyViewModel() {
  const creditPrice = parseInt(process.env.REACT_APP_CREDIT_PRICE);
  const [error, setError] = useState<IError>();
  const [promoCode, setPromoCode] = useState<string>('');
  const [count, setCount] = useState<number>(1);
  const [totalPrice, setTotalPrice] = useState<number>(creditPrice * count);
  const { onSetShow } = useContext(BuyContext);

  const {
    tokens: { accessToken },
  } = useContext(AuthorizationContext);

  function onClose() {
    onSetShow(false);
  }

  const onChange = (value: number) => {
    if (!isNaN(value)) {
      setCount(value);
      setTotalPrice(creditPrice * value);
    } else {
      setCount(0);
      setTotalPrice(creditPrice);
    }

    setError({ type: EError.COUNT, message: '', status: false });
  };

  const onBuy = async () => {
    if (count >= 1) {
      try {
        const result = (await createPayment(count, accessToken)) as ICreatePayment;
        window.location.replace(result.url);
      } catch (err) {
        console.log(err);
        setError({ type: EError.PAYMENT, message: 'Something wrong, please try later', status: true });
      }
    } else {
      setError({ type: EError.COUNT, message: 'Credit count is wrong', status: true });
    }
  };

  const onPromoCode = async () => {
    if (count >= 1) {
      try {
        const result = (await createPromoCode(promoCode, accessToken)) as ICreatePayment;
        if(result) {
          window.location.href = window.location.origin;
        } else {
          setError({ type: EError.PAYMENT, message: 'Something wrong, please try later', status: true });
        }
      } catch (err) {
        console.log(err);
        setError({ type: EError.PAYMENT, message: 'Something wrong, please try later', status: true });
      }
    } else {
      setError({ type: EError.COUNT, message: 'Credit count is wrong', status: true });
    }
  };

  return {
    totalPrice,
    creditPrice,
    count,
    error,
    promoCode,
    setPromoCode,
    onPromoCode,
    onClose,
    onChange,
    onBuy,
  };
}
