/* eslint-disable import/no-unresolved */
import Quote from 'components/Quote';

import { Swiper, SwiperSlide } from 'swiper/react';

import 'swiper/css';

import s from './QuotesArray.module.scss';

interface TOwnProps {
  quotes?: Array<{
    id: string;
    name?: string;
    quote?: string;
    commentaries?: {
      id: string;
      name?: string;
      commentary?: string;
    };
  }>;
}

const QuotesArray = ({ quotes }: TOwnProps) => (
  <div className={s.root}>
    <Swiper allowTouchMove={false} noSwiping loop autoHeight>
      {quotes.length > 1
        ? quotes.map((item) => (
            <SwiperSlide key={item.id}>
              <Quote
                name={item.name}
                quote={item.quote}
                commentaries={item.commentaries}
                isVisibleButtons={quotes.length > 1}
              />
            </SwiperSlide>
          ))
        : null}

      {quotes.length === 1
        ? quotes.map((item) => (
            <Quote
              key={item.id}
              name={item.name}
              quote={item.quote}
              commentaries={item.commentaries}
              isVisibleButtons={quotes.length > 1}
            />
          ))
        : null}
    </Swiper>
  </div>
);
export default QuotesArray;
