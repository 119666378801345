import { useState, useEffect } from 'react';

interface IQuoteArrDevelop {
  id: string;
  value: string;
}

const DevelopViewModel = ({setToStorage}: any) => {
  const [selectBlock, setSelectBlock] = useState<number>(null)

  const getQuoteArrDevelop = (): IQuoteArrDevelop[] => {
    return JSON.parse(localStorage.getItem('quoteArrDevelop'));
  }

  const up = (index: number) => {
    const item = getQuoteArrDevelop();
    if (index === 0){
      return false
    }

    const current = item[index];
    const up = item[index - 1];

    item[index] = up;
    item[index - 1] = current;
    setToStorage(item)
  }

  const down = (index: number) => {
    const item = getQuoteArrDevelop();
    if (index === item.length - 1 || index === item.length - 2){
      return false
    }

    const current = item[index];
    const up = item[index + 1];

    item[index] = up;
    item[index + 1] = current;
    setToStorage(item)
  }

  const remove = (cIndex: number) => {
    const items = getQuoteArrDevelop();
    setToStorage(items.filter((_, index) => index !== cIndex))
  }

  return {
    up, down, remove, selectBlock, setSelectBlock
  }
};

export default DevelopViewModel;
