import cn from 'classnames';
import { useSwiper } from 'swiper/react';
import ReactMarkdown from 'react-markdown';

import Button from 'components/Button';
import Text from 'components/Text';
import Title from 'components/Title';

import s from './Quote.module.scss';

interface TOwnProps {
  name: string;
  quote: string;
  subTitlte?: string;
  className?: string;
  onShowComments?: () => void;
  onHideComments?: () => void;
  isCommentVisible: boolean;
}

const Quote = ({
  name,
  subTitlte = '123',
  quote,
  onShowComments = () => null,
  onHideComments = () => null,
  isCommentVisible,
  className,
}: TOwnProps) => {
  const swiper = useSwiper();
  const handleShowComments = () => {
    onShowComments();
    setTimeout(() => {
      swiper.updateAutoHeight();
    }, 100);
  };
  const handleCloseComments = () => {
    onHideComments();
    setTimeout(() => {
      swiper.updateAutoHeight();
    }, 100);
  };
  return (
    <div className={cn(s.quoet, className)}>
      {subTitlte ? (
        <div className={s.header}>
          <Text className={s.subTitle} size="extra-small" muted>
            {subTitlte}
          </Text>
        </div>
      ) : null}

      <div className={s.content}>
        {name ? (
          <Title level={6} className={s.title}>
            {name}
          </Title>
        ) : null}

        {isCommentVisible ? (
          <Text className={s.contentText}>{quote}</Text>
        ) : (
          <Text className={s.contentText} inline>
            <ReactMarkdown>{quote}</ReactMarkdown>
          </Text>
        )}
      </div>
      <div className={s.footer}>
        {isCommentVisible ? (
          <Button theme="ghost" className={s.footerTextBlue} onClick={handleCloseComments}>
            Back to quotation
          </Button>
        ) : (
          <Button theme="ghost" className={s.footerTextBlue} onClick={handleShowComments}>
            Commentary
          </Button>
        )}
      </div>
    </div>
  );
};

export default Quote;
