import { ReactNode, useState } from 'react';

import Text from 'components/Text';
import Button from 'components/Button';

import s from './Accordion.module.scss';
import { PlusIcon, MinusIcon } from 'components/Icons';
import DefinitionView from 'components/Definition/DefinitionView';

const Accordion = ({
  title,
  definition,
  children,
}: {
  title: string;
  definition: string;
  children: ReactNode | string;
}) => {
  const [active, setActive] = useState<boolean>();

  const handleToggleActive = () => {
    setActive((prevState) => !prevState);
  };

  return (
    <div className={s.root}>
      <Button theme="ghost" className={s.header} onClick={handleToggleActive}>
        <Text className={s.title} weight="bold" size="medium">
          {title}
        </Text>
        {active ? <MinusIcon /> : <PlusIcon />}
      </Button>
      {active ? <div className={s.content}>{children}</div> : null}
      <DefinitionView title={definition} />
    </div>
  );
};
export default Accordion;
