import { ReactNode } from 'react';
import cn from 'classnames';
import Title from 'components/Title';

import s from './BigTitle.module.scss';

interface TOwnProps {
  className?: string;
  children: ReactNode | string;
}

const BigTitle = ({ children, className }: TOwnProps) => (
  <Title level={2} className={cn(s.root, className)} weight="bold">
    {children}
  </Title>
);

export default BigTitle;
