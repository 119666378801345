import { useContext } from 'react';

import cn from 'classnames';

import logo from 'assets/images/logo.svg';
import Button from 'components/Button';

// import useLocalStorage from 'hooks/useLocalStorage';

import { RoutingContext, pagesMapping } from 'context/Routing';

import s from './Header.module.scss';
import { DataContext } from 'context/Data';
import Text from 'components/Text';
import Spinner from 'components/Spinner';
import { AuthorizationContext } from '../../context/Authorization';
import Popup from 'reactjs-popup';
import { CloseSmallIcon } from 'components/Icons';
import BuyView from 'components/Buy/BuyView';
import HeaderViewModel from 'components/Header/HeaderViewModel';

interface TOwnProps {
  className?: string;
}

const Header: React.FC<JSX.IntrinsicElements['header']> = ({ className }: TOwnProps) => {
  const { onBuyCredit } = HeaderViewModel();
  const { isLogin, onLogout, user } = useContext(AuthorizationContext);
  // const [emailLocalStorage, setEmailLocalStorage] = useLocalStorage('emailAuth', '');
  // const [, setIdAuth] = useLocalStorage('idAuth', '');

  const { isSaveData } = useContext(DataContext);

  const { setPage, page } = useContext(RoutingContext);

  // const handleClearEmail = () => {
  //   setEmailLocalStorage('');
  //   setIdAuth('');
  //   if (setPage) {
  //     setPage(pagesMapping.home);
  //   }
  // };

  const handleGoToMyAccount = () => {
    if (setPage) {
      setPage(pagesMapping.home);
    }
  };

  const isMyAccountPage = page === pagesMapping.home;

  return (
    <header className={cn(s.root, className)}>
      <BuyView />
      <div className={s.content}>
        <img src={logo} className={s.logo} alt="logo" />
        <div className={s.buttons}>
          {isLogin ? (
            <div className={s.credit_container}>
              <p>
                <span>{user?.balance || 0}</span> credits available
              </p>
              <button onClick={() => onBuyCredit()}>buy more credits</button>
            </div>
          ) : null}

          {isSaveData ? (
            <div className={s.save}>
              <Spinner />
              <Text className={s.text}>All changes saved</Text>
            </div>
          ) : null}

          {isLogin ? (
            <Button theme="white" className={s.button} onClick={handleGoToMyAccount} size="medium">
              My account
            </Button>
          ) : null}

          {isMyAccountPage && isLogin ? (
            <Button theme="ghost" className={s.button} onClick={() => onLogout()} size="small">
              Exit
            </Button>
          ) : null}
        </div>
      </div>
    </header>
  );
};

export default Header;
