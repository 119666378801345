import React, { useState, TextareaHTMLAttributes } from 'react';

import TextareaAutosize from 'react-textarea-autosize';

import cn from 'classnames';

export interface TextareaPropsTypes extends TextareaHTMLAttributes<HTMLTextAreaElement> {
  isError?: boolean;
  onFocus?: (e: React.FocusEvent) => void;
  onBlur?: (e: React.FocusEvent) => void;
}

import s from './TextArea.module.scss';

const TextArea = React.forwardRef<HTMLTextAreaElement, TextareaPropsTypes>(
  ({ disabled = false, isError = false, name, className, placeholder, value, onChange, ...props }, ref) => {
    const [hasFocus, setFocus] = useState(false);

    const handleFocus = (event: React.FocusEvent<HTMLTextAreaElement>) => {
      const { onFocus } = props;

      setFocus(true);
      onFocus?.(event);
    };

    const handleBlur = (event: React.FocusEvent<HTMLTextAreaElement>) => {
      const { onBlur } = props;

      setFocus(false);
      onBlur?.(event);
    };

    const classNames = cn(s.root, className, hasFocus && s.focus, disabled && s.disabled, isError && s.error);

    return (
      <TextareaAutosize
        className={classNames}
        id={name}
        name={name}
        onFocus={handleFocus}
        onBlur={handleBlur}
        placeholder={placeholder}
        ref={ref}
        value={value}
        onChange={onChange}
      />
    );
  }
);

export default TextArea;
