import { useContext, useEffect } from 'react';

import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

import { StepsContext, stepsMapping } from 'context/Steps';

import Button from 'components/Button';
import Title from 'components/Title';
import Text from 'components/Text';
import Point from 'components/Point';
import Prompt from 'components/Prompt';

import DustBinNew from '../DustBinNew';

import useLocalStorage from 'hooks/useLocalStorage';

import smileImg from 'assets/smiles/smile.png';
import { DataContext } from 'context/Data';

import s from './Develop.module.scss';
import DevelopViewModel from 'modules/steps/components/Develop/Develop.view.model';
import { ArrowDownIcon, ArrowUpIcon, DeleteIcon, RemoveIcon } from 'components/Icons';

export const ItemTypes = {
  POINT: 'point',
};

const textAreaArr = [
  {
    id: '1',
    value: '',
    placeholder: 'Make your first point',
  },
];

const Develop = ({ title }: { title: string }) => {
  const [arr] = useLocalStorage<{ id?: number; value: string }[]>('quoteArr', []);
  const [quoteArrDevelop, setQuoteArrDevelop] = useLocalStorage<{ id: string; value: string; placeholder?: string }[]>(
    'quoteArrDevelop',
    textAreaArr
  );


  const {up, down, remove, selectBlock, setSelectBlock} = DevelopViewModel({setToStorage: setQuoteArrDevelop});

  const [planValue, setPlanValue] = useLocalStorage('planValue', '');

  const { onUpdateWritingProcess } = useContext(DataContext);

  const { setStep } = useContext(StepsContext);

  const handleNextStep = () => {
    if (setStep) {
      setStep(stepsMapping.third);
      onUpdateWritingProcess();
    }
  };

  useEffect(() => {
    if (planValue) {
      onUpdateWritingProcess();
    }
  }, [planValue]);

  const isShowPoints = arr.length > 0 && arr[0].value !== '';

  const onAddTextArea = () => {
    setQuoteArrDevelop((prevState) => [
      ...prevState,
      {
        id: `${new Date().getTime()}`,
        value: '',
      },
    ]);
  };

  const handleChange = (value: string, index: number) => {
    // setQuoteArrDevelop((prevState) => {
    //   const newArr = prevState.slice();
    //
    //   newArr[index].value = value;
    //
    //   return newArr;
    // });

    setQuoteArrDevelop((prevState) => {
      const newArr = prevState.slice();
      newArr[index].value = value;

      const lastColumn = newArr[newArr.length - 1];
      if (lastColumn.value === '' || lastColumn.value.length === 0) {
        return newArr;
      } else {
        return [
          ...newArr,
          {
            id: `${new Date().getTime()}`,
            value: '',
          },
        ];
      }
    });
  };

  const handleDropChange = (value: string, index: number) => {
    const trimmedValue = value.replace(/^\s+|\s+$/g, '');

    setQuoteArrDevelop((prevState) => {
      const newArr = prevState.slice();

      newArr[index].value = newArr[index].value ? newArr[index].value + trimmedValue : trimmedValue;

      return newArr;
    });
  };

  const mappedPlan = quoteArrDevelop.map((item) => item.value);

  const textPlan = mappedPlan.join('\n\n');

  useEffect(() => {
    setPlanValue(textPlan);
  }, [textPlan]);

  return (
    <DndProvider backend={HTML5Backend}>
      <div className={s.row}>
        <aside className={s.aside}>
          <div className={s.points}>
            <Title level={5} className={s.subtitleAside}>
              My points and examples
            </Title>

            {isShowPoints ? (
              arr.map((item, index) => <Point name={`Point ${index + 1}`} quote={item.value} key={index} />)
            ) : (
              <Text>No points(</Text>
            )}
          </div>
        </aside>

        <div className={s.cell}>
          <Title level={4} className={s.subtitle}>
            {title}
          </Title>

          <Prompt className={s.description}>
            <b>Congratulations on reaching the EXPLAIN & DEVELOP step!</b>
            <br />
            This is where you breathe life into your points and transform them into a well-rounded and nuanced argument. Your task here is twofold. First, interpret the textual evidence you have selected to support your points: dig beneath the surface, analyze its significance, and uncover the layers of meaning it holds. Second, develop your interpretation by considering various angles. This may include (1) exploring the connection between the chosen textual evidence and other parts of the studied work; (2) reflecting on the effect the chosen evidence has on you and other readers, taking into account the perspectives of the critics you have read; (3) establishing relevant contextual links, such as cultural changes, the author's biography, or literary genres and intellectual movements of the period.
          </Prompt>

            <div className={s.textArea}>
              {quoteArrDevelop.map((item, index) => (
               <div key={index} className={s.pointMainColum}>
                 <DustBinNew
                   onChange={(value: string) => handleChange(value, index)}
                   // onDropChange={(value: string) => handleDropChange(value, index)}
                   onDropChange={(value: string) => handleChange(value, index)}
                   value={item.value}
                   key={item.id || index}
                   placeholder={item.placeholder || 'Explain & develop'}
                   onAddComponentTextArea={onAddTextArea}
                   placeholderText={'Drag the point of your plan from the list on the left'}
                   activeBlock={selectBlock === index ? 'selected' : ''}
                 />
                 {quoteArrDevelop.length !== index + 1 && quoteArrDevelop.length > 2 &&
                   <div className={s.mainColumArrow}>
                     <div className={s.columArrow}
                          onClick={() => up(index)}
                          onMouseEnter={() => setSelectBlock(index)}
                          onMouseLeave={() => setSelectBlock(null)}>
                       <ArrowUpIcon />
                     </div>
                     <div className={s.columArrow}
                          onClick={() => down(index)}
                          onMouseEnter={() => setSelectBlock(index)}
                          onMouseLeave={() => setSelectBlock(null)}>
                       <ArrowDownIcon />
                     </div>
                     <div className={s.columArrow}
                          onClick={() => remove(index)}
                          onMouseEnter={() => setSelectBlock(index)}
                          onMouseLeave={() => setSelectBlock(null)}>
                       <DeleteIcon />
                     </div>
                   </div>
                 }
               </div>
              ))}

              {/* <DustBinDevelop
                value={planValue}
                onChange={(value) => setPlanValue(value)}
                onDropChange={(value) => setPlanValue((prevState) => prevState + value)}
              /> */}
            </div>

          <div className={s.footer}>
            <Text size="medium" weight="medium" className={s.footerText}>
              <img src={smileImg} width="40" height="40" className={s.img} />
              Are you happy with your argument? If yes, press continue!
            </Text>

            <Button onClick={handleNextStep} size="large">
              Continue
            </Button>
          </div>
        </div>
      </div>
    </DndProvider>
  );
};

export default Develop;
