import { useDrag } from 'react-dnd';

import ReactMarkdown from 'react-markdown';

import Text from 'components/Text';

import s from './Point.module.scss';

export const ItemTypes = {
  POINT: 'point',
};

interface TOwnProps {
  name?: string;
  quote: string;
}

const Point = ({ name, quote }: TOwnProps) => {
  const [{ isDragging }, drag] = useDrag(() => ({
    type: ItemTypes.POINT,
    item: { quote },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
      handlerId: monitor.getHandlerId(),
    }),
  }));

  const opacity = isDragging ? 0.4 : 1;

  return (
    <div className={s.root} style={{ opacity }} ref={drag}>
      {name ? (
        <Text className={s.name} weight="bold">
          {name}
        </Text>
      ) : null}
      {quote ? (
        <Text className={s.text} inline>
          <ReactMarkdown>{quote}</ReactMarkdown>
        </Text>
      ) : null}
    </div>
  );
};

export default Point;
