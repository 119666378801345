import { useState } from 'react';
import cn from 'classnames';
import Button from 'components/Button';
import Title from 'components/Title';

import s from './ShowMoreTextBlock.module.scss';

interface TOwnProps {
  className?: string;
  data: { title: string; description?: string; descriptionShowMore?: string, smallDesc?: string };
}

const ShowMoreTextBlock = ({ className, data }: TOwnProps) => {
  const { title = '', description, descriptionShowMore = null, smallDesc } = data;
  const [showMore, setShowMore] = useState<boolean>(false);
  const handleToggleShowMore = () => {
    setShowMore((prevState) => !prevState);
  };
  return (
    <div className={cn(s.root, className)}>
      <Title level={3} className={s.title}>
        {title}
        <p className={s.small_desc}>{smallDesc}</p>
      </Title>

      {description ? <p className={s.description} dangerouslySetInnerHTML={{ __html: description }} /> : null}

      {descriptionShowMore ? (
        <>
          {showMore ? <p className={s.description} dangerouslySetInnerHTML={{ __html: descriptionShowMore }} /> : null}

          <Button theme="secondary" onClick={handleToggleShowMore} className={s.button}>
            {showMore ? 'Close' : 'CONTEXTUAL FACTORS & CRITICAL OPINIONS'}
          </Button>
        </>
      ) : null}
    </div>
  );
};

export default ShowMoreTextBlock;
