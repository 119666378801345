import { useState, useContext } from 'react';

import ReactMarkdown from 'react-markdown';

import cn from 'classnames';

import Title from 'components/Title';
import Text from 'components/Text';
import Button from 'components/Button';
import { RoutingContext, pagesMapping } from 'context/Routing';
import s from './ReviewScreen.module.scss';

const ReviewScreenSubmitted = () => {
  const { setPage } = useContext(RoutingContext);

  const questionObj = {name: ''};

  const items = JSON.parse(window.localStorage.getItem('allProcess')) || [];
  const [_, id] = window?.location?.hash?.split('#')

  const item = items.find((item) => item.id === id);
  const planValue = item?.data?.develop?.planValue || '';
  const introduction = item?.data?.develop?.introduction || '';
  const conclusion = item?.data?.develop?.conclusion || '';

  const handleBack = () => {
    if (setPage) {
      window.location.hash = '';
      setPage(pagesMapping.home);
    }
  };

  return (
    <>
      <div className={cn(s.root, 'container')}>
        <Title level={2} className={s.title}>
          {questionObj.name}
        </Title>

        <div className={s.content}>
          <Title level={5} className={s.subTitle}>
            Introduction
          </Title>

          <Text size="small-medium">{introduction}</Text>
        </div>

        <div className={s.content}>
          <Title level={5} className={s.subTitle}>
            Body
          </Title>

          <Text size="small-medium" inline>
            <ReactMarkdown>{planValue}</ReactMarkdown>
          </Text>
        </div>

        <div className={s.content}>
          <Title level={5} className={s.subTitle}>
            Conclusion
          </Title>

          <Text size="small-medium">{conclusion}</Text>
        </div>

        <div className={s.footer}>
          <Button onClick={handleBack} theme="gray-blue" size="large">
            Back
          </Button>
        </div>
      </div>
    </>
  );
};

export default ReviewScreenSubmitted;
